var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-ET-manage" },
    [
      _vm.loading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "ET-loading-box",
            attrs: {
              "element-loading-text": "加载中，请稍后",
              "element-loading-spinner": "el-icon-loading",
              "element-loading-background": "rgba(0, 0, 0, 0.8)",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "project-ET-status" },
        [
          _c(
            "el-input",
            {
              staticClass: "project-search-input uav-input",
              attrs: { onclick: "search", placeholder: _vm.$t("inputPlease") },
              model: {
                value: _vm.projectName,
                callback: function ($$v) {
                  _vm.projectName = $$v
                },
                expression: "projectName",
              },
            },
            [
              _c("template", { slot: "suffix" }, [
                _c("div", { staticClass: "search-icon-container" }, [
                  _c("i", {
                    staticClass: "el-icon-search",
                    on: {
                      click: function ($event) {
                        return _vm.search()
                      },
                    },
                  }),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "project-ET-list" },
        _vm._l(_vm.projectData, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              class:
                item.id === _vm.selectProject
                  ? "project-ET-list-item project--choosen"
                  : "project-ET-list-item",
              on: {
                click: function ($event) {
                  return _vm.chooseProject(item)
                },
              },
            },
            [
              _c("p", { staticClass: "item-top" }, [
                _c(
                  "span",
                  { staticClass: "item-top-left" },
                  [
                    _c("span", { staticClass: "item-top-title" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("StatusLabel", { attrs: { labelStatus: item.status } }),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c(
                      "el-dropdown",
                      { on: { command: _vm.changeItem } },
                      [
                        _c("span", { staticClass: "el-dropdown-link" }, [
                          _c("img", {
                            staticClass: "icon icon-more",
                            attrs: {
                              src: "/img/project/icon-more.png",
                              alt: "",
                            },
                          }),
                        ]),
                        _c(
                          "el-dropdown-menu",
                          {
                            staticClass: "black-style",
                            attrs: { slot: "dropdown" },
                            slot: "dropdown",
                          },
                          [
                            _vm.permission.ET_edit && _vm.showProjectDetail
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.beforeHandleCommand(
                                        "edit",
                                        item
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("btnEdit")) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  command: _vm.beforeHandleCommand("end", item),
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("btnEnd")) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm.permission.ET_del && _vm.showProjectDetail
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: {
                                      command: _vm.beforeHandleCommand(
                                        "delete",
                                        item
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("btnDelete")) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("p", { staticClass: "item-bottom" }, [
                _c("span", { staticClass: "item-title" }, [
                  _vm._v(
                    _vm._s(_vm.$t("user.emergencyranks.name")) +
                      "：" +
                      _vm._s(item.ranksNames)
                  ),
                ]),
              ]),
              _c("p", { staticClass: "item-bottom" }, [
                _c("span", { staticClass: "item-title" }, [
                  _vm._v(
                    _vm._s(_vm.$t("user.emergencyranks.leaderName")) +
                      "：" +
                      _vm._s(item.leaderName)
                  ),
                ]),
              ]),
              _c("p", { staticClass: "item-bottom" }, [
                _c("span", { staticClass: "item-bottom-time" }, [
                  _vm._v(
                    _vm._s(_vm.$t("eventType")) +
                      "：" +
                      _vm._s(item.eventTypeName)
                  ),
                ]),
              ]),
              _c(
                "p",
                {
                  staticClass: "item-right",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toDetail(item)
                    },
                  },
                },
                [
                  _vm.showProjectDetail
                    ? _c("span", { staticClass: "item-bottom-project" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("manage")) +
                            ">>\n        "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "project-add" },
        [
          _vm.permission.ET_edit && _vm.hasProjectView
            ? _c(
                "el-button",
                {
                  staticClass: "project-add-btn",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addProject },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("addTask")) + "\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "common-map-dialog private-ET-panel-dialog",
          attrs: {
            width: "600px",
            title: _vm.$t("emergency.endTaskDesc"),
            visible: _vm.showEndTask,
            "before-close": _vm.closeEndTask,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEndTask = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "endForm",
                  attrs: { model: _vm.endForm, "label-width": "150px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("emergency.endTaskSummary") } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.endForm.summary,
                          callback: function ($$v) {
                            _vm.$set(_vm.endForm, "summary", $$v)
                          },
                          expression: "endForm.summary",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.closeEndTask } }, [
                _vm._v(_vm._s(_vm.$t("cancelText"))),
              ]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.submitLoading, type: "primary" },
                  on: { click: _vm.submitSetting },
                },
                [_vm._v(_vm._s(_vm.$t("submitText")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }