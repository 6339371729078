var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.className + " status-label" }, [
    _vm._v(
      "\n  " +
        _vm._s(
          _vm.labelStatus === 1
            ? "未开始"
            : _vm.labelStatus === 2
            ? "进行中"
            : "已完成"
        ) +
        "\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }