<template>
  <div class="operation-bar" :style="$route.name!=='emergency-task'? `padding-top: 49px` : ``">
    <ProjectList
      v-if="$route.name==='emergency-task'"
      @editProject="editProject"
      @detailProject="detailProject"
      @addProject="addProjectToItem"
    />
    <!-- 三级路由侧边栏 -->
    <div v-if="showThirdMenu" class="detail-tabs" >
      <div
        v-for="item in thirdMenu" 
        :key="item.path"
        :class="$route.name === item.code? 'detail-tabs-item detail-tabs-item--active': 'detail-tabs-item'"
        @click="changeActive(item)">
        <p class="detail-tabs-item--top">
          <img
            class="icon"
            :src="
              $route.name === item.code?
              `/img/emergency/${item.code}-active.png`:
              `/img/emergency/${item.code}.png`
            "
            alt=""
          />
        </p>
        <p class="detail-tabs-item--bottom">{{item.name}}</p>
      </div>
    </div>
    <router-view class="third-routing" v-if="thirdRouterViewer"/>
  </div>
</template>

<script>
import ProjectList from "./projectList";
import { getEmergencytaskDetail } from "@/api/emergency/index";
import { mapGetters } from "vuex";

export default {
  name: "operationBar",
  components: {
    ProjectList
  },
  data() {
    return {
      projectId: null,
      detailItem: {}
    };
  },
  computed: {
    ...mapGetters([
      'permission',
      'menuAll'
    ]),
    // 三级菜单
    thirdMenu() {
      for (var i = 0; i < this.menuAll.length; i++) {
        const item = this.menuAll[i]
        if (item.code === 'emergency') {
          if (item.children && Array.isArray(item.children)) {
            for (let j = 0; j < item.children.length; j++) {
              const element = item.children[j];
              if (element.code === 'emergency-task') {
                if (element.children && Array.isArray(element.children) && element.children.length > 0) {
                  return element.children
                }
              }
            }
          }
        }
      }
      return []
    },
    showThirdMenu() {
      if (this.$route.name !== 'emergency-task') {
        if (this.$route.query && this.$route.query.action) {
          return false
        } 
        return true
      }
      return false
    },
    thirdRouterViewer(){
      return this.$route.name !== 'emergency-task' && this.$route.matched.length > 2
    }
  },
  methods: {
    editProject(item) {
      const params = {
        id: item.id,
      };
      getEmergencytaskDetail(params).then((res) => {
        if (res.data.code === 200) {
          this.detailItem = res.data.data;
          this.$router.push({
            path: '/emergency/emergency-task/ET-detail',
            query: {
              projectId: item.id,
              id: item.flyProjectID,
              action: 'edit'
            }
          })
        }
      });
    },
    detailProject(item, moveTo) {
      // 飞行项目id
      const id =  item.flyProjectID
      // 巡检对象id
      const projectId = item.id
      const projectName = item.name
      const arr = item.eventLocation.split(',')
      if (moveTo && arr.length >= 2) {
        this.$EventBus.$emit('map-flyTo', parseFloat(arr[0]), parseFloat(arr[1]))
      }
      for (var i = 0; i < this.menuAll.length; i++) {
        const item = this.menuAll[i]
        if (item.code === 'emergency') {
          if (item.children && Array.isArray(item.children)) {
            for (let j = 0; j < item.children.length; j++) {
              const element = item.children[j];
              if (element.code === 'emergency-task') {
                if (element.children && Array.isArray(element.children) && element.children.length > 0) {
                  this.$router.push({
                    path: element.children[0].path,
                    query: {
                      id,
                      projectId,
                      projectName
                    }
                  })
                }
              }
            }
          }
        }
      }
    },
    addProjectToItem() {
      this.$store.dispatch("setProjectPoint", null);
      this.$router.push({
        path: '/emergency/emergency-task/ET-detail',
        query: {
          action: 'add'
        }
      })
    },
    changeActive(node) {
      const projectName = this.$route.query.projectName
      const projectId = this.$route.query.projectId
      const id = this.$route.query.id
      this.$router.push({
        path: node.path,
        query: {
          id,
          projectId,
          projectName
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.operation-transition-leave-active {
  transition: opacity 0.2s;
}
.operation-transition-enter-active {
  transition: opacity 2.5s;
}
.operation-transition-enter,
.operation-transition-leave-to {
  opacity: 0;
}
.operation-bar {
  height: 100%;
  display: flex;
  background-color: rgba(30, 34, 42, 1);
  .detail-tabs {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    width: 55px;
    border-right: 1px solid #030303;
    border-top: 1px solid #030303;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      color: #76787d;
      font-size: 12px;
      position: relative;
      .icon {
        height: 20px;
      }
      &--active {
        font-weight: bold;
        // font-size: 14px;
        color: #3a76ff;
        &::after {
          position: absolute;
          content: "";
          width: 52px;
          height: 6px;
          background-image: url("/img/project/icon-active.png");
          background-size: cover;
          bottom: 0px;
          // left: 3px;
        }
      }
    }
    .detail-tabs-item--bottom {
      text-align: center;
    }
  }
  .third-routing {
    width: 290px;
    border-top: 1px solid #030303;
    height: calc(100% - 54px);
  }
}
</style>

