<template>
  <div class="container normal emergency-task-box">
    <operationBar/>
    <CusiumMap v-show="showCusiumMap" class="emergency-task-lastItem"/>
    <map-records v-if="mapRecordsLoaded" v-show="showMapRecords"></map-records>
  </div>
</template>

<script>
import CusiumMap from "@/components/cesium/index";
import operationBar from "./operationBar/";
import mapRecords from '../ET-map-records/index.vue'

export default {
  name: "ET-page",
  components: {
    CusiumMap,
    operationBar,
    mapRecords
  },
  computed: {
    showMapRecords() {
      return this.$route.name === 'ET-record'
    },
    showCusiumMap() {
      return this.$route.name !== 'ET-record'
    }
  },
  watch:{
    $route() {
      if (!this.mapRecordsLoaded && this.$route.name === 'ET-record') {
        this.mapRecordsLoaded = true 
      }
    }
  },
  mounted() {
    if (!this.mapRecordsLoaded && this.$route.name === 'ET-record') {
      this.mapRecordsLoaded = true 
    }
  },
  data() {
    return {
      mapRecordsLoaded: false
    }
  }
};
</script>

<style lang="scss">
.emergency-task-box{
  width: 100%;
  height: 100%;
  display: flex;
  .emergency-task-lastItem{
    flex-grow: 1;
    overflow: hidden;
  }
}
</style>
