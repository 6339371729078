<template>
  <div class="project-ET-manage">
    <div 
      v-loading="loading"
      v-if="loading"
      element-loading-text="加载中，请稍后"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="ET-loading-box">
    </div>
    <div class="project-ET-status">
      <el-input
        class="project-search-input uav-input"
        v-model="projectName"
        onclick="search"
        :placeholder="$t('inputPlease')">
        <template slot="suffix">
          <div class="search-icon-container">
            <i class="el-icon-search" @click="search()"></i>
          </div>
        </template>
      </el-input>
    </div>
    <div class="project-ET-list">
      <div
        v-for="item in projectData"
        :key="item.id"
        @click="chooseProject(item)"
        :class="item.id === selectProject? 'project-ET-list-item project--choosen': 'project-ET-list-item'">
        <p class="item-top">
          <span class="item-top-left">
            <span class="item-top-title">{{ item.name }}</span>
            <StatusLabel :labelStatus="item.status"/>
          </span>
          <span @click.stop>
            <el-dropdown 
              @command="changeItem">
              <span class="el-dropdown-link">
                <img
                  class="icon icon-more"
                  src="/img/project/icon-more.png"
                  alt=""/>
              </span>
              <el-dropdown-menu class="black-style" slot="dropdown">
                <el-dropdown-item
                  v-if="permission.ET_edit && showProjectDetail"
                  :command="beforeHandleCommand('edit', item)">
                  {{$t('btnEdit')}}
                </el-dropdown-item>
                <el-dropdown-item
                  :command="beforeHandleCommand('end', item)">
                  {{$t('btnEnd')}}
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="permission.ET_del && showProjectDetail"
                  :command="beforeHandleCommand('delete', item)">
                  {{$t('btnDelete')}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </p>
        <p class="item-bottom">
          <span class="item-title">{{$t('user.emergencyranks.name')}}：{{ item.ranksNames }}</span>
        </p>
        <p class="item-bottom">
          <span class="item-title">{{$t('user.emergencyranks.leaderName')}}：{{ item.leaderName }}</span>
        </p>
        <p class="item-bottom">
          <span class="item-bottom-time">{{$t('eventType')}}：{{ item.eventTypeName }}</span>
        </p>
        <p class="item-right"  @click.stop="toDetail(item)">
          <span
            v-if="showProjectDetail"
            class="item-bottom-project">
            {{$t('manage')}}>>
          </span>
        </p>
      </div>
    </div>
    <div class="project-add">
      <el-button
        v-if="permission.ET_edit && hasProjectView"
        class="project-add-btn"
        type="primary"
        icon="el-icon-plus"
        @click="addProject">
        {{$t('addTask')}}
      </el-button>
    </div>
    <!-- 结束任务弹窗 -->
    <el-dialog
      width="600px"
      :title="$t('emergency.endTaskDesc')"
      :visible.sync="showEndTask"
      :before-close="closeEndTask"
      class="common-map-dialog private-ET-panel-dialog"
      :close-on-click-modal="false"
      append-to-body>
      <div>
        <el-form ref="endForm" :model="endForm" label-width="150px">
          <el-form-item :label="$t('emergency.endTaskSummary')">
            <el-input type="textarea" v-model="endForm.summary"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="closeEndTask">{{ $t("cancelText") }}</el-button>
        <el-button
          :loading="submitLoading"
          @click="submitSetting"
          type="primary"
          >{{ $t("submitText") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import StatusLabel from "@/components/project/statusLabel";
import { mapGetters, mapMutations } from "vuex";
import { getEmergencytaskPage, deleteEmergencytask } from "@/api/emergency/index";
import { getList } from '@/api/system/dept';
import { updateEmergencytask } from "@/api/emergency/index";

export default {
  name: "emergency-task",
  components: {
    StatusLabel,
  },
  computed: {
    ...mapGetters([
      'selectProject',
      'permission',
      'menuAll'
    ]),
    hasProjectView() {
      for (var i = 0; i < this.menuAll.length; i++) {
        const item = this.menuAll[i]
        if (item.code === 'emergency') {
          if (item.children && Array.isArray(item.children)) {
            for (let j = 0; j < item.children.length; j++) {
              const element = item.children[j];
              if (element.code === 'emergency-task') {
                if (element.children && Array.isArray(element.children) && element.children.length > 0) {
                  let children = element.children
                  for (let k = 0; k < children.length; k++) {
                    if (children[k].code === 'ET-detail') {
                      return true
                    }
                  }
                }
              }
            }
          }
        }
      }
      return false
    },
    showProjectDetail() {
      for (var i = 0; i < this.menuAll.length; i++) {
        const item = this.menuAll[i]
        if (item.code === 'emergency') {
          if (item.children && Array.isArray(item.children)) {
            for (let j = 0; j < item.children.length; j++) {
              const element = item.children[j];
              if (element.code === 'emergency-task') {
                if (element.children && Array.isArray(element.children) && element.children.length > 0) {
                  return true
                }
              }
            }
          }
        }
      }
      return false
    }
  },
  data() {
    return {
      loading: true,
      showEndTask: false,
      submitLoading: false,
      projectName: "",
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "未开始",
        },
        {
          value: "2",
          label: "进行中",
        },
        {
          value: "3",
          label: "已完成",
        },
      ],
      data: [],
      projectData: [],
      deptTree:[],
      endForm: {
        summary: ''
      }
    };
  },
  mounted() {
    this.getList();
    this.getDeptList()
  },
  methods: {
    ...mapMutations([
      'SET_MAP3D_ET_LIST'
    ]),
    beforeHandleCommand(flag, command) {
      return {
        flag: flag,
        command: command,
      };
    },
    changeItem(val) {
      const item = val.command;
      const type = val.flag;
      switch (type) {
        case "edit":
          this.$emit("editProject", item);
          this.$store.commit("SET_PROJECT", item);
          break;
        case "end":
          if (item.status === 3) {
            return this.$message.info(this.$t('emergency.hadEnd'))
          }
          this.showEndTask = true
          this.endForm = item
          break;
        case "delete":
          this.$confirm("是否确认删除该项目", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            deleteEmergencytask({
              ids: item.id,
            }).then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.getList();
              }
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
          break;
        default:
          return;
      }
    },
    getDeptList() {
      getList().then(res=>{
        if (res.data.code === 200) {
          this.deptTree = res.data.data
        }
      })
    },
    // 选择项目
    chooseProject(item) {
      this.$store.dispatch("setSelectProject", item.id);
    },
    toDetail(item) {
      this.$emit("detailProject", item, true);
      // todo 以后核对下这个vuex内容是否还需要
      this.$store.commit("SET_PROJECT", item);
    },
    addProject() {
      this.$emit("addProject");
    },
    search(){
      this.getList({
        name: this.projectName
      });
    },
    getList(parmas) {
      this.loading = true
      if(!parmas) {
        parmas = {}
      }
      parmas.current = 1;
      parmas.size = 10000;
      getEmergencytaskPage(parmas).then((res) => {
        if (res.data.code === 200) {
          this.projectData = res.data.data.records.map(item=>{
            item.centerPoint = item.eventLocation
            return item
          });
          this.SET_MAP3D_ET_LIST(this.projectData)
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    getDepartment(ids) {
      let res = ''
      if (typeof ids === 'string') {
        ids = JSON.parse(ids)
      }
      if (ids && Array.isArray(ids)) {
        for (var i = 0; i < ids.length; i++) {
          res = res + this.getDeptNameFromTree(ids[i])
        }
      }
      return res
    },
    getDeptNameFromTree(id, list) {
      if (!list) {
        list = this.deptTree
      }
      let res = ''
      for (var i = 0; i < list.length; i++) {
        if (list.children && Array.isArray(list.children)) {
          res = this.getDeptNameFromTree(id, list.children)
        }
        if (id === list[i].id) {
          res = list[i].deptName
          return res
        }
      }
      return res
    },
    closeEndTask() {
      this.showEndTask = false
      this.$refs.endForm.resetFields()
    },
    submitSetting() {
      this.submitLoading = true
      this.endForm.status = 3
      updateEmergencytask(this.endForm).then((res) => {
        if (res.data.code === 200) {
          this.$message.success(res.data.msg);
          this.closeEndTask()
          this.getList()
        }
      }).catch((err) => {
        this.$message.error(err.msg);
      }).finally(()=>{
        this.submitLoading = false;
      })
    }
  },
  beforeDestroy() {
    // 清空缓存
    this.$store.dispatch("setSelectProject", null);
  }
};
</script>

<style lang="scss" scoped>
.project-ET-manage {
  width: 100%;
  padding-top: 5px;
  height: 100%;
  position: relative;
  .project-ET-status {
    width: calc(100% - 10px);
    padding: 5px 5px;
    .uav-select {
      .el-input input {
        font-size: 14px;
        font-family: MicrosoftYaHeiUI;
        color: #ffffff;
      }
    }
    .search-icon-container {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .project-ET-list {
    margin-top: 5px;
    max-height: calc(100% - 120px);
    overflow-y: overlay;
    width: 290px;
    &-item {
      padding: 13px 23px 11px 16px;
      box-sizing: border-box;
      position: relative;
      border-top: 2px solid #030303;
      cursor: pointer;
      &--choosen {
        background-color: red;
      }

      .icon {
        &-more {
          // position: absolute;
          // top: 7px;
          // right: 15px;
          height: 10px;
          cursor: pointer;
          padding: 5px 10px;
          margin-right: -6px;
        }
        &-location {
          position: absolute;
          top: 32px;
          right: 11px;
          height: 14px;
          cursor: pointer;
          padding: 5px 10px;
        }
        &-user {
          width: 11px;
          line-height: 14px;
        }
      }

      &:last-child {
        border-bottom: 1px solid #030303;
      }

      .item-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;
        &-left {
          display: flex;
          justify-content: flex-start;
          max-width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-title {
          font-size: 14px;
          font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
          font-weight: bold;
          color: #ffffff;
          margin-right: 10px;
        }
      }

      .item-center {
        font-size: 12px;
        font-family: MicrosoftYaHeiUI;
        color: #ffffff;
        line-height: 15px;
        margin-bottom: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 50px;
      }

      .item-right {
        font-size: 12px;
        font-family: MicrosoftYaHeiUI;
        color: #ffffff;
        line-height: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: right;
      }

      .item-top-title {
        display: inline-block;
        width: 120px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .item-bottom {
        display: flex;
        margin-bottom: 8px;
        justify-content: space-between;
        font-size: 12px;
        font-family: MicrosoftYaHeiUI;
        color: #ffffff;
        &-user {
          display: flex;
          align-items: center;
          .user {
            margin-left: 4px;
          }
        }
        .item-bottom-time{
          color: rgba(153, 153, 153, 0.79);
        }
      }
    }
    .project--choosen{
      background-color: #40434a;
    }
  }

  .project-add {
    margin-top: 15px;
    margin-left: 10px;
    &-btn {
      width: 267px;
      height: 36px;
      line-height: 36px;
      background: #265edd;
      border-radius: 3px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #ffffff;
      padding: 0;
      border: none;
    }
  }

  .ET-loading-box{
    position: absolute;
    height: 100%;
    width: 100%;
  }
}
</style>
