var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ET-records-map-box" },
    [
      _c("div", { attrs: { id: "cesiumContainer_ET_records" } }),
      _vm.siderContentShow
        ? _c("div", { ref: "footer", staticClass: "footer" }, [
            _c("div", { staticClass: "play-controller" }, [
              _c("div", { style: "width:" + _vm.processingTextWidth + "px" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.passedTime + "/" + _vm.totalTime) +
                    "\n      "
                ),
              ]),
              _c(
                "span",
                { staticClass: "video-play", on: { click: _vm.triggerPlay } },
                [
                  _vm.videoLoading
                    ? _c("i", { staticClass: "el-icon-loading" })
                    : _vm.play
                    ? _c("i", { staticClass: "el-icon-video-pause" })
                    : _c("i", { staticClass: "el-icon-video-play" }),
                ]
              ),
              _c(
                "div",
                {
                  ref: "processing",
                  staticClass: "processing",
                  on: { click: _vm.setControllerPosition },
                },
                [
                  _c("el-progress", {
                    attrs: { percentage: _vm.percentage, "show-text": false },
                  }),
                  _c("div", {
                    staticClass: "processing-controller",
                    style: "left:" + _vm.percentage + "%",
                    on: { mousedown: _vm.startProgress },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "tool-controller" }),
          ])
        : _vm._e(),
      _vm.selectEmergencyRecord && _vm.selectEmergencyRecord.id
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingEmergencyRecord,
                  expression: "loadingEmergencyRecord",
                },
              ],
              staticClass: "inspection-pannel",
              class: _vm.ETRecordsInfoClass,
              attrs: {
                "element-loading-text": _vm.$t("loading"),
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0.8)",
              },
            },
            [
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("eventType")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showETRecord.eventTypeName) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("emergency.taskName")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showETRecord.emergencyTaskName) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("FilghtTask")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showETRecord.flyTaskName) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("emergency.device")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showETRecord.equipmentName) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("startTime")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showETRecord.startTime) +
                      "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("endTime")) + ": "),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.showETRecord.endTime) + "\n      "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "inspection-pannel-item" }, [
                _c("span", { staticClass: "pannel-item-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("emergency.duration")) +
                      ":\n      "
                  ),
                ]),
                _c("span", { staticClass: "pannel-item-content" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.showETRecord.recordDuration) +
                      "\n      "
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: _vm.showMarkPanel ? "open-ET-records-panel" : "",
          attrs: { id: "baseController" },
        },
        [
          false
            ? _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    "popper-class": "nofly-tooltip",
                    effect: "dark",
                    placement: "left-start",
                    "visible-arrow": false,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "nofly-tooltip-main",
                      attrs: { slot: "content" },
                      slot: "content",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "tooltip-main_top" },
                        [
                          _c("el-switch", {
                            attrs: {
                              size: "",
                              "active-text": "显示禁飞区",
                              "inactive-text": "",
                            },
                            on: { change: _vm.changenNoflySwitch },
                            model: {
                              value: _vm.noflySwitchData,
                              callback: function ($$v) {
                                _vm.noflySwitchData = $$v
                              },
                              expression: "noflySwitchData",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "tooltip-main_bottom" },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.noflyCheckListData,
                                callback: function ($$v) {
                                  _vm.noflyCheckListData = $$v
                                },
                                expression: "noflyCheckListData",
                              },
                            },
                            _vm._l(_vm.noflyTypeValueData, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.label,
                                  attrs: {
                                    border: "",
                                    size: "small",
                                    disabled: item.disabled,
                                    label: item.label,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeNoflyBox(item.label)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/project/" + item.img + ".png",
                                      alt: "",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t(item.name))),
                                  ]),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "no-fly" }, [
                    _c("img", {
                      attrs: { src: "/img/map/noFly.svg", alt: "" },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
          _c("img", {
            style: "transform: rotate(" + _vm.degrees + "deg)",
            attrs: {
              id: "navigationHelpButtonContainer",
              src: "/img/map/compass.png",
            },
          }),
          _c("div", { staticClass: "other" }, [
            _c(
              "div",
              {
                staticClass: "btn zoomBtn bottomBorder",
                staticStyle: {
                  "border-top-right-radius": "4px",
                  "border-top-left-radius": "4px",
                },
                on: {
                  click: function ($event) {
                    return _vm.zoom(1)
                  },
                },
              },
              [_c("img", { attrs: { src: "/img/map/放大.png" } })]
            ),
            _c(
              "div",
              {
                staticClass: "btn zoomBtn",
                staticStyle: {
                  "border-bottom-right-radius": "4px",
                  "border-bottom-left-radius": "4px",
                },
                on: {
                  click: function ($event) {
                    return _vm.zoom(-1)
                  },
                },
              },
              [_c("img", { attrs: { src: "/img/map/缩小.png" } })]
            ),
            _c(
              "div",
              {
                attrs: { title: "" + (_vm.isVec ? "卫星图" : "矢量图") },
                on: { click: _vm.switchLayerType },
              },
              [
                _c("img", {
                  staticClass: "layerSwitch",
                  attrs: {
                    src: "/img/map/" + (_vm.isVec ? "3D.png" : "2D.png"),
                  },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "sider-content",
          class: _vm.showMarkPanel ? "open-ET-records-panel" : "",
          style:
            "width: 460px; right: 28px; top: " +
            (_vm.windowHeight === "normal" ? "420" : "260") +
            "px;",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.siderContentShow,
                  expression: "siderContentShow",
                },
              ],
              staticClass: "content-header",
            },
            [
              _c("span", { staticClass: "content-title" }, [
                _vm._v("视频回放"),
              ]),
              _c(
                "span",
                { staticClass: "close", on: { click: _vm.closeSiderContent } },
                [_c("img", { attrs: { src: "/img/map/close.png" } })]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "content",
              class: _vm.siderContentShow ? "height-258" : "",
            },
            [
              _c(
                "div",
                { staticClass: "video-container" },
                [
                  _c("xg-player", {
                    ref: "videoLive",
                    attrs: {
                      dialogData: _vm.showETRecord,
                      videoUrl: _vm.videoUrl,
                      videoType: _vm.showETRecord.videoFileType,
                      controls: false,
                      lonArr: _vm.lonArr,
                      show: false,
                    },
                    on: {
                      "warning-success": _vm.warningSuccess,
                      showLive: _vm.showLive,
                      play: _vm.videoPlay,
                      pause: _vm.videoPause,
                      ended: _vm.videoEnd,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "close",
                        fn: function () {
                          return [_c("div", { staticClass: "videoBtn" })]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c("mark-panel", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loadingRightPanel,
            expression: "loadingRightPanel",
          },
        ],
        staticClass: "ET-records-panel",
        attrs: {
          canEdit: false,
          pathList: [],
          entity: _vm.markEntity,
          colorList: _vm.markColorList,
          showHandlePath: false,
          "element-loading-text": _vm.$t("loading"),
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(0, 0, 0, 0.8)",
          visible: _vm.showMarkPanel,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showMarkPanel = $event
          },
        },
      }),
      _c(
        "div",
        {
          staticClass: "scaler",
          class: _vm.showMarkPanel ? "open-ET-records-panel" : "",
        },
        [
          _c("div", {
            staticClass: "scaler-text",
            domProps: {
              textContent: _vm._s(
                _vm.scale < 1000 ? _vm.scale + "m" : _vm.scale / 1000 + "km"
              ),
            },
          }),
          _c("div", { staticClass: "scaler-ui" }),
        ]
      ),
      _vm.hangarShow
        ? _c("HangarDetails", {
            ref: "hangarDetails",
            attrs: {
              show: _vm.hangarShow,
              baseData: _vm.hangarBaseData,
              detailsData: _vm.hangarDetailsData,
              videoData: _vm.hangarVideoData,
            },
            on: {
              "update:show": function ($event) {
                _vm.hangarShow = $event
              },
              closeDetails: _vm.closeHangarPanel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }