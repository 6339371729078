<template>
  <div :class="`${className} status-label`">
    {{ labelStatus === 1 ? "未开始" : labelStatus === 2 ? "进行中" : "已完成" }}
  </div>
</template>

<script>
export default {
  props: {
    labelStatus: {
      type: Number,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      className: "",
    };
  },
  watch: {
    labelStatus: {
      handler: function (n) {
        switch (n) {
          case 1:
            this.className = "status-label--todo";
            break;
          case 2:
            this.className = "status-label--normal";
            break;
          case 3:
            this.className = "status-label--finish";
            break;
          default:
            this.className = "status-label--finish";
            return;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.status-label {
  // width: 42px;
  padding: 2px 5px;
  height: 17px;
  // border-radius: 9px;
  font-family: MicrosoftYaHeiUI;
  font-size: 10px;
  text-align: center;
  color: #130f0fe3;
  width: 42px;
  border-radius: 9px;
  // opacity: 0.27;
  // background-color: #9a9b9f;
  &--todo {
    color: #9ecaff;
    background: #265edd;
  }
  &--normal {
    // color: #82FF00 ;
    // background: #e8f8f2;
    background: #82FF00 27%;

  }
  &--finish {
    color: #ffffff;
    background: #454A50;
  }
}
</style>