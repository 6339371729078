var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container normal emergency-task-box" },
    [
      _c("operationBar"),
      _c("CusiumMap", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCusiumMap,
            expression: "showCusiumMap",
          },
        ],
        staticClass: "emergency-task-lastItem",
      }),
      _vm.mapRecordsLoaded
        ? _c("map-records", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showMapRecords,
                expression: "showMapRecords",
              },
            ],
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }