<template>
  <div class="ET-records-map-box">
    <div id="cesiumContainer_ET_records"></div>
    <!-- 进度条 -->
    <div 
      v-if="siderContentShow"
      ref="footer"
      class="footer"
      >
      <div class="play-controller">
        <div :style="`width:${processingTextWidth}px`">
          {{ `${passedTime}/${totalTime}` }}
        </div>
        <span @click="triggerPlay" class="video-play">
          <i v-if="videoLoading" class="el-icon-loading"></i>
          <i v-else-if="play" class="el-icon-video-pause"></i>
          <i v-else class="el-icon-video-play"></i>
        </span>
        <div @click="setControllerPosition" ref="processing" class="processing">
          <el-progress
            :percentage="percentage"
            :show-text="false"
          ></el-progress>
          <div
            @mousedown="startProgress"
            :style="`left:${percentage}%`"
            class="processing-controller"
          ></div>
        </div>
      </div>
      <div class="tool-controller"></div>
    </div>
    <!-- 信息面板 -->
    <div
      v-if="selectEmergencyRecord && selectEmergencyRecord.id"
      v-loading="loadingEmergencyRecord"
      :element-loading-text="$t('loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :class="ETRecordsInfoClass"
      class="inspection-pannel"
    >
      <!-- 事件类型 -->
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("eventType") }}: </span>
        <span class="pannel-item-content">
          {{ showETRecord.eventTypeName }}
        </span>
      </div>
      <!-- 任务名称 -->
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("emergency.taskName") }}: </span>
        <span class="pannel-item-content">
          {{ showETRecord.emergencyTaskName }}
        </span>
      </div>
      <!-- 飞行任务 -->
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("FilghtTask") }}: </span>
        <span class="pannel-item-content">
          {{ showETRecord.flyTaskName }}
        </span>
      </div>
      <!-- 设备 -->
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("emergency.device") }}: </span>
        <span class="pannel-item-content">
          {{ showETRecord.equipmentName }}
        </span>
      </div>
      <!-- 开始时间 -->
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("startTime") }}: </span>
        <span class="pannel-item-content">
          {{ showETRecord.startTime }}
        </span>
      </div>
      <!-- 结束时间 -->
      <div class="inspection-pannel-item">
        <span class="pannel-item-title"> {{ $t("endTime") }}: </span>
        <span class="pannel-item-content">
          {{ showETRecord.endTime }}
        </span>
      </div>
      <!-- 任务时长 -->
      <div class="inspection-pannel-item">
        <span class="pannel-item-title">
          {{ $t("emergency.duration") }}:
        </span>
        <span class="pannel-item-content">
          {{ showETRecord.recordDuration }}
        </span>
      </div>
    </div>
    <!-- 右下工具栏 -->
    <div
      id="baseController"
      :class="showMarkPanel ? 'open-ET-records-panel' : ''"
    >
      <el-tooltip class="item" popper-class="nofly-tooltip" effect="dark" placement="left-start" 
        :visible-arrow="false" v-if="false">
        <div slot="content" class="nofly-tooltip-main">
          <div class="tooltip-main_top">
            <el-switch v-model="noflySwitchData" size active-text="显示禁飞区" 
              inactive-text="" @change="changenNoflySwitch" />
          </div>
          <div class="tooltip-main_bottom">
            <el-checkbox-group v-model="noflyCheckListData">
              <el-checkbox border size="small" v-for="item in noflyTypeValueData" :key="item.label" 
                :disabled="item.disabled" :label="item.label" @change="changeNoflyBox(item.label)">
                <img :src="`/img/project/${item.img}.png`" alt="">
                <span>{{$t(item.name)}}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="no-fly">
          <img src="/img/map/noFly.svg" alt="">
        </div>
      </el-tooltip>
      <img
        id="navigationHelpButtonContainer"
        src="/img/map/compass.png"
        :style="'transform: rotate(' + degrees + 'deg)'"
      />
      <div class="other">
        <!-- <div
            class="btn zoomBtn model-changer flexCenter"
            @click="changeViewModel">
            {{ viewModel === 3 ? "2D" : "3D" }}
        </div> -->
        <div
          class="btn zoomBtn bottomBorder"
          style="border-top-right-radius: 4px; border-top-left-radius: 4px"
          @click="zoom(1)"
        >
          <img src="/img/map/放大.png" />
        </div>

        <div
          class="btn zoomBtn"
          style="
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          "
          @click="zoom(-1)"
        >
          <img src="/img/map/缩小.png" />
        </div>

        <div @click="switchLayerType" :title="`${isVec ? '卫星图' : '矢量图'}`">
          <img
            class="layerSwitch"
            :src="`/img/map/${isVec ? '3D.png' : '2D.png'}`"
          />
        </div>
      </div>
    </div>
    <!-- 视频回放 -->
    <div
      :class="showMarkPanel ? 'open-ET-records-panel' : ''"
      class="sider-content"
      :style="`width: 460px; right: 28px; top: ${
        windowHeight === 'normal' ? '420' : '260'
      }px;`"
    >
      <div v-show="siderContentShow" class="content-header">
        <span class="content-title">视频回放</span>
        <span class="close" @click="closeSiderContent">
          <img src="/img/map/close.png" />
        </span>
      </div>
      <div :class="siderContentShow ? 'height-258' : ''" class="content">
        <div class="video-container">
          <xg-player
            ref="videoLive"
            :dialogData="showETRecord"
            :videoUrl="videoUrl"
            :videoType="showETRecord.videoFileType"
            :controls="false"
            @warning-success="warningSuccess"
            @showLive="showLive"
            @play="videoPlay"
            @pause="videoPause"
            @ended="videoEnd"
            :lonArr="lonArr"
            :show="false">
            <template v-slot:close>
              <div class="videoBtn"></div>
            </template>
          </xg-player>
        </div>
      </div>
    </div>
    <!-- 巡检对象属性面板 -->
    <mark-panel
      class="ET-records-panel"
      :canEdit="false"
      :pathList="[]"
      :entity="markEntity"
      :colorList="markColorList"
      :showHandlePath="false"
      v-loading="loadingRightPanel"
      :element-loading-text="$t('loading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :visible.sync="showMarkPanel"
    />
    <!-- 比例尺 -->
    <div class="scaler" :class="showMarkPanel ? 'open-ET-records-panel' : ''">
      <div
        class="scaler-text"
        v-text="scale < 1000 ? `${scale}m` : `${scale / 1000}km`"
      ></div>
      <div class="scaler-ui"></div>
    </div>
    <!-- 机库详情弹窗 -->
    <HangarDetails v-if="hangarShow" ref="hangarDetails" :show.sync="hangarShow" :baseData="hangarBaseData" 
      :detailsData="hangarDetailsData" :videoData="hangarVideoData" @closeDetails="closeHangarPanel" />
  </div>
</template>

<script>
import Map3d from "@/components/cesium/js/Map3d";
import BaseLayer from "@/components/cesium/js/BaseLayer";
import NodeMap from "@/components/cesium/js/node/index";
import xgPlayer from "@/components/video-live-xgplayer/index";
import MarkVue from "@/components/cesium/mixins/mark.vue";
import airportShowVue from "@/components/cesium/mixins/airportShow.vue";
import hangarVue from "@/components/cesium/mixins/hangar.vue";
import hangarShowVue from "@/components/cesium/mixins/hangarShow.vue";
import markPanel from "@/components/cesium/components/mark-panel.vue";
import { getMarkObjects } from "@/api/project/label";
import { getUavDataModel, findEquipmentModel } from "@/api/map/index";
import { mapGetters, mapMutations, mapActions } from "vuex";
import EditOperation from '@/components/cesium/js/interactive/operation/EditOperation';
import { getEmergencyRecordDetail } from "@/api/emergency/index";
import plane from "@/assets/img/map/XC_64x64_.png";
import { getAlarmDetailById } from "@/api/project/index";
import { addEvent, removeEvent } from "@/util/util";
import {getNoFlyZoneList} from '@/api/noFlyZone/index';
import userNoFlyZoneHook from '@/components/cesium/js/hooks/noFlyZoneHook';

export default {
  name: "cesiumViewer",
  mixins: [ MarkVue, airportShowVue, hangarVue, hangarShowVue ],
  components: { markPanel, xgPlayer },
  props: {
    dialogData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 2d、3d 模式
      viewModel: Cesium.SceneMode.SCENE3D,
      windowHeight: "normal",
      loadingRightPanel: false,
      loadingEmergencyRecord: false,
      scale: null,
      degrees: 0,
      // 进度条
      videoLoading: false,
      // 是否播放
      play: false,
      // 播放条显示进度
      percentage: 0,
      // 是否开启拖动
      controlDroping: false,
      // 实际播放进度
      propessControler: 0,
      // 当前i时间显示宽度
      processingTextWidth: 150,
      // 开始时间
      startTime: new Date().getTime(),
      // 结束时间
      endTime: new Date().getTime(),
      // 无人机图层
      flylineLayer: new BaseLayer({ name: "flylineLayer" }),
      // 巡检导航路线图层
      navigationLayer: new BaseLayer({ name: "navigationLayer" }),
      // 禁飞区相关
      firstRenderNofly: true,
      noflySwitchData: false,
      noFlyData: [],
      temporaryData: [],
      heightLimitData: [],
      bufferZoneData: [],
      customZoneData: [],
      noflyCheckListData: [],
      noflyTypeValueData: [],
      // 当缓存数据
      currentList: [],
      // 当前缓存数据最大条数
      maxlength: 20,
      // 当前播放的数据的位置
      currentIndex: 0,
      // 每次加载的数据量
      trackLength: 10,
      // 地图2D、3D状态
      isVec: false,
      // 巡检对象属性面板编辑
      showMarkPanel: false,
      // 视频显示
      siderContentShow: false,
      //展示点击的巡检对象
      showETRecord: {},
      // 右侧属性面板
      markEntity: {
        lng: null,
        lat: null,
        linePositions: [],
      },
      // 视频播放结束
      videoHasEnded: false,
      // 无人机图标
      plane,
      // chunkLoading
      currentLoadingChunkId: "",
      // 需要停止的加载片段的ids
      stopLoadingChunkMap: {},
      lonArr: [],
    };
  },
  computed: {
    ...mapGetters([
      "selectEmergencyRecord",
      "markColorList",
      // 禁飞区
      "noflySwitch",
      "noflyCheckList",
      "noflyTypeValue"
    ]),
    //
    passedTime() {
      let total = this.endTime - this.startTime;
      let current = (this.percentage * total) / 100;
      let date = new Date(parseInt(current));
      let Hour = date.getHours() >= 8 ? date.getHours() - 8 : 24 - date.getHours();
      let Minute = date.getMinutes();
      let Sechond = date.getSeconds();
      Hour = Hour < 10 ? "0" + Hour : Hour;
      Minute = Minute < 10 ? "0" + Minute : Minute;
      Sechond = Sechond < 10 ? "0" + Sechond : Sechond;
      let GMT = (Hour === "00" ? "" : Hour + ":") + Minute + ":" + Sechond;
      return GMT;
    },
    // 总时间
    totalTime() {
      let date = new Date(parseInt(this.endTime - this.startTime));
      let Hour =
        date.getHours() >= 8 ? date.getHours() - 8 : 24 - date.getHours();
      let Minute = date.getMinutes();
      let Sechond = date.getSeconds();
      Hour = Hour < 10 ? "0" + Hour : Hour;
      Minute = Minute < 10 ? "0" + Minute : Minute;
      Sechond = Sechond < 10 ? "0" + Sechond : Sechond;
      let GMT = Hour + ":" + Minute + ":" + Sechond;
      return GMT;
    },
    // 视频地址
    videoUrl() {
      if (this.selectEmergencyRecord && this.selectEmergencyRecord.videoUrl) {
        return this.selectEmergencyRecord.videoUrl;
      }
      return "";
    },
    ETRecordsInfoClass() {
      let className = "";
      if (this.windowHeight !== "normal") {
        className = "min-margin";
      }
      if (this.showMarkPanel) {
        className = className + " open-ET-records-panel";
      }
      return className;
    },
  },
  watch: {
    selectEmergencyRecord: {
      handler(now) {
        if (now && now.hasOwnProperty("id")) {
          this.showETRecord = {};
          this.changeViewer();
        } else {
          this.flylineLayer.clear();
          this.navigationLayer.clear();
          this.closeSiderContent();
          this.showETRecord = {};
        }
        this.getHangarData(now);
      },
      immediate: true,
    },
    $route(value) {
      if (value && value.name === 'ET-record') {
        this.initBusEvent()
        this.initNoFlyConfig();
      } else {
        this.unbindBusEvents()
      }
    }
  },
  created() {
    this.initNoFlyConfig();
  },
  mounted() {
    // 加载地图
    this.closeSiderContent();
    addEvent(window, "resize", this.resize);
    this.resize();
    this.initMap();
    this.requestMarksLayer();
    this.initBusEvent();
  },
  methods: {
    ...mapActions(["setWsTaskInfo"]),
    ...mapMutations([
      'SET_NO_FLY_SWITCH',
      'SET_NO_FLY_CHECKLIST',
      'SET_NO_FLY_TYPE_VALUE'
    ]),

    //
    initMap() {
      const viewer = new Map3d("cesiumContainer_ET_records", {
        sceneMode: this.viewModel,
      });
      this.$options.map3d = viewer;
      this.initLayer();
      this.getNoFlyData();
      this.getAirportData();
      if (this.$options.timer) {
        clearTimeout(this.$options.timer);
        this.$options.timer = null;
      }
      this.currentIndex = 0;
      this.setEditModel();
      // 弹窗位置与球体拖拽事件关联
      viewer.scene.postRender.addEventListener(() => {
        let self = this;
        const heading = viewer.scene.camera.heading;
        if (heading) {
          self.degrees = 360 - Cesium.Math.toDegrees(heading);
        } else {
          self.degrees = 0;
        }
        const { distance } = viewer.getScale();
        self.scale = distance;
      });
    },

    // 初始化图层
    initLayer() {
      this.$options.map3d.layerManager.add(this.flylineLayer);
      this.$options.map3d.layerManager.add(this.navigationLayer);
      // 初始化禁飞区图层
      this.$options.noFlyZoneHook = userNoFlyZoneHook();
      this.$options.noFlyZoneHook.setMap({map3d: this.$options.map3d, layerID: 'noFlyLayer'});
      this.initAirportShowLayer();
      this.initHangarLayer();
    },
    // 绑定监听事件
    initBusEvent() {
      this.$EventBus.$off("flyto-ET-record");
      this.$EventBus.$on("flyto-ET-record", () => {
        this.moveToNavigationById(this.selectEmergencyRecord.id);
      });
    },

    unbindBusEvents() {
      // 定位到entity
      this.$EventBus.$off("fly-to-by-id");
      // 定位区间
      this.$EventBus.$off("map-flyTo");
    },

    changeViewer() {
      // 暂停播放
      this.closeSiderContent();
      this.flylineLayer.clear();
      this.navigationLayer.clear();
      this.currentList = [];
      this.getEmergencyRecordDetail()
      // 改变进度条
      this.percentage = 0;
      this.currentIndex = 0;
      this.endTime = this.startTime;
    },

    requestMarksLayer() {
      const projectId = this.$route.query.projectId
      getMarkObjects({
        projectId
      }).then((res) => {
        const code = res.data.code;
        if (code === 200) {
          const data =
            res.data.data && Array.isArray(res.data.data) ? res.data.data : [];
          this.addMarks(data);
        }
      });
    },

    addMarks(data) {
      this.$options.marksLayer = new BaseLayer({
        name: "$标注展示图层$",
        id: "$标注展示图层$"
      })
      this.$options.map3d.layerManager.add(this.$options.marksLayer);
      for (let j = 0; j < data.length; j++) {
        const mark = data[j];
        const node = this.createMarkNode(mark);
        this.$options.marksLayer.addNode(node);
      }
    },

    getPlaneIcon(sn) {
      findEquipmentModel({
        sn,
      })
        .then((res) => {
          if (res.data.code === 200) {
            const data = res.data.data;
            if (data && data.modelIcon) {
              this.plane = data.modelIcon;
            }
          }
        })
        .finally(() => {});
    },

    addNavigationInMap(data) {
      try {
        const geojson = data.uavTrackData;
        if (!geojson) {
          return
        }
        const type = geojson.type;
        const coordinates = geojson.coordinates;
        let node;
        var positions = [];
        for (let k = 0; k < coordinates.length; k++) {
          const coordinate = coordinates[k];
          const position = Cesium.Cartesian3.fromDegrees(...coordinate);
          positions.push(position);
        }
        node = new NodeMap.NavigationLine({
          id: data.id,
          positions,
          show: true,
          name: data.inspectionName,
        });
        this.navigationLayer.addNode(node);
      } catch (error) {
        console.log(error);
      }
    },

    switchVideoURL() {
      return new Promise((resolve, reject) => {
        this.$refs.videoLive
          .switchURL()
          .then(() => {
            this.endTime = new Date(
              this.startTime + this.$refs.videoLive.player.duration * 1000
            ).getTime();
            this.videoLoading = false;
            this.play = true;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    resize(e) {
      const h = document.documentElement.clientHeight;
      if (h < 850) {
        this.windowHeight = `small`;
      } else {
        this.windowHeight = `normal`;
      }
    },

    videoPlay() {
      this.videoLoading = false;
      this.play = true;
      if (this.videoHasEnded) {
        this.videoHasEnded = false;
        this.flylineLayer.clear();
        setTimeout(() => {
          this.startChecked();
        }, 1000);
      } else {
        this.startChecked();
      }
    },

    videoPause() {
      this.play = false;
    },

    videoEnd() {
      this.endChecked();
      this.currentIndex = 0;
      this.percentage = 0;
      this.play = false;
      this.videoHasEnded = true;
    },

    startProgress() {
      // this.controlDroping = true
    },

    footerMouseUp(evt) {
      if (this.controlDroping) {
        this.controlDroping = false;
      }
    },

    footerMouseMove(e) {},

    setControllerPosition(evt) {
      if (!this.$refs.videoLive.player) {
        return;
      }
      this.videoLoading = true;
      this.flylineLayer.clear();
      this.endChecked();
      var left = evt.clientX;
      var footer = this.$refs.footer.getBoundingClientRect();
      // 鼠标相对footer的Dom元素的位置
      var leftFooter = left - footer.left;
      // 鼠标相对进度条的位置
      var leftPro = leftFooter - 52 - this.processingTextWidth;
      if (leftPro < 0) {
        this.percentage = 0;
      } else if (footer.width < leftFooter + 120) {
        this.percentage = 100;
      } else {
        this.percentage =
          (leftPro * 100) /
          (footer.width - 52 - this.processingTextWidth - 120);
      }
      let duration = this.$refs.videoLive.player.duration;
      let currentTime = (duration * this.percentage) / 100;
      this.$refs.videoLive.setCurrentTime(currentTime);
      this.currentIndex = parseInt(currentTime);
      // console.log(`currentIndex:`, this.currentIndex);
      setTimeout(() => {
        this.startChecked();
        this.videoLoading = false;
      }, 500);
    },

    // 禁飞区相关
    initNoFlyConfig() {
      this.noflySwitchData = this.noflySwitch;
      this.noflyCheckListData = this.noflyCheckList;
      this.noflyTypeValueData = this.noflyTypeValue;
      if (!this.firstRenderNofly) {
        // 如果不是首次渲染处理禁飞区图层的显隐
        this.changenNoflySwitch(this.noflySwitchData, 'isShow');
      }
      this.firstRenderNofly = false;
    },
    getNoFlyData() {
      this.clearNoFlyLayer();
      getNoFlyZoneList({flyZoneType: 1}).then(res => {
        if (res.data.code == 200) {
          this.noFlyData = res.data.data || [];
          this.createNoFlyLayer(this.noFlyData, 'noFly');
        }
      });
      getNoFlyZoneList({flyZoneType: 2}).then(res => {
        if (res.data.code == 200) {
          this.temporaryData = res.data.data || [];
          this.createNoFlyLayer(this.temporaryData, 'temporary');
        }
      });
      getNoFlyZoneList({flyZoneType: 3}).then(res => {
        if (res.data.code == 200) {
          this.heightLimitData = res.data.data || [];
          this.createNoFlyLayer(this.heightLimitData, 'heightLimit');
        }
      });
      getNoFlyZoneList({flyZoneType: 4}).then(res => {
        if (res.data.code == 200) {
          this.bufferZoneData = res.data.data || [];
          this.createNoFlyLayer(this.bufferZoneData, 'bufferZone');
        }
      });
      getNoFlyZoneList({flyZoneType: 5}).then(res => {
        if (res.data.code == 200) {
          this.customZoneData = res.data.data || [];
          this.createNoFlyLayer(this.customZoneData, 'customZone');
        }
      });
    },
    clearNoFlyLayer() {
      this.$options.noFlyZoneHook.clearRenderLayer();
    },
    createNoFlyLayer(data, type) {
      let contents = [];
      data && data.map(t => contents.push({...t.content, id: t.id, name: t.name}));
      let layerStyle = {
        noFlyType: type,
        show: false,
        data: contents
      };
      if (this.noflySwitchData && this.noflyCheckListData.find(t => t === type)) layerStyle.show = true;
      switch (type) {
        case 'noFly':
          layerStyle.overlayColor = '#FF1414';
          layerStyle.overlayAlpha = 0.28;
          layerStyle.outLineColor = '#FF1414';
          layerStyle.outLineWidth = 2;
          break;
        case 'temporary':
          layerStyle.overlayColor = '#FF1414';
          layerStyle.overlayAlpha = 0.3;
          layerStyle.polylineDash = true;
          layerStyle.outLineColor = '#FFB600';
          layerStyle.outLineWidth = 2;
          break;
        case 'heightLimit':
          layerStyle.overlayColor = '#FFC300';
          layerStyle.overlayAlpha = 0.3;
          layerStyle.outLineColor = '#FFC300';
          layerStyle.outLineWidth = 0;
          break;
        case 'bufferZone':
          layerStyle.overlayColor = '#B0B0B0';
          layerStyle.overlayAlpha = 0.4;
          layerStyle.outLineColor = '#2B2B2B';
          layerStyle.outLineWidth = 2;
          break;
        case 'customZone':
          layerStyle.overlayColor = '#C600FF';
          layerStyle.overlayAlpha = 0.28;
          layerStyle.outLineColor = '#C600FF';
          layerStyle.outLineWidth = 2;
          break;
      }
      this.$options.noFlyZoneHook.handlingNoFlyData(layerStyle);
    },
    changenNoflySwitch(val, type) {
      this.SET_NO_FLY_SWITCH(val);
      this.noflyTypeValueData.map(t => t.disabled = !val);
      this.SET_NO_FLY_TYPE_VALUE(this.noflyTypeValueData);
      if (!val || (val && !type)) {
        // 隐藏/打开所有禁飞区
        this.noflyCheckListData.map(t => {
          if (t && this[`${t}Data`]) {
            this[`${t}Data`].map(i => {
              this.$options.noFlyZoneHook.changeShowEntity(i.id, val);
            });
          }
        });
      } else {
        this.noflyTypeValueData.map(t => {
          let isShow = this.noflyCheckListData.find(i => i === t.label) ? true : false;
          if (this[`${t.label}Data`]) {
            this[`${t.label}Data`].map(i => {
              this.$options.noFlyZoneHook.changeShowEntity(i.id, isShow);
            });
          }
        });
      }
    },
    changeNoflyBox(val) {
      this.SET_NO_FLY_CHECKLIST(this.noflyCheckListData);
      if (!this[`${val}Data`]) return;
      let isShow = this.noflyCheckListData.find(t => t === val) ? true : false;
      this[`${val}Data`].map(t => {
        this.$options.noFlyZoneHook.changeShowEntity(t.id, isShow);
      });
    },

    // 开始和停止播放
    triggerPlay() {
      if (!this.play) {
        this.startPlay();
      } else {
        this.endPlay();
      }
    },

    startPlay(stamp) {
      if (!this.showETRecord && !this.showETRecord.id) {
        return;
      }
      this.$refs.videoLive.play(stamp);
    },

    endPlay() {
      if (this.$refs.videoLive && this.$refs.videoLive.pause) {
        this.$refs.videoLive.pause();
      }
      this.endChecked();
    },

    startChecked() {
      this.checkedPlayer();
    },

    endChecked() {
      this.stopLoadingTrack();
      if (this.$options.timer) {
        clearTimeout(this.$options.timer);
        this.$options.timer = null;
      }
    },

    checkedPlayer() {
      if (this.play) {
        let currentTime =
          this.$refs.videoLive && this.$refs.videoLive.player
            ? this.$refs.videoLive.player.currentTime
            : 0;
        if (this.endTime - this.startTime > 0) {
          this.percentage =
          (currentTime * 1000 * 100) / (this.endTime - this.startTime);
        } else {
          this.percentage = 0
        }
        
        
        this.drawTrack();
        this.$options.timer = setTimeout(() => {
          this.checkedPlayer();
        }, 1000);
      }
    },

    // 开始轨迹播放
    drawTrack() {
      const startTime = this.getStampByDateFomat(
        this.selectEmergencyRecord.startTime
      );
      let currentTime = parseInt(this.$refs.videoLive.player.currentTime);
      let requestStartTime = this.currentIndex * 1000 + startTime;
      if (!this.theChunkHasLoaded(this.trackLength, requestStartTime)) {
        this.loadTrack(Cesium.createGuid());
        return;
      }
      let lastIndex = this.currentIndex;
      if (this.currentIndex === 0) {
        for (var i = 0; i < currentTime - this.currentIndex + 1; i++) {
          if (this.currentIndex + i < this.currentList.length) {
            if (!this.currentList[this.currentIndex + i]) {
              break;
            }
            lastIndex++;
            this.updateFlyLine(this.currentList[this.currentIndex + i]);
          }
        }
        this.currentIndex = lastIndex;
      } else {
        for (var i = 0; i < currentTime - this.currentIndex; i++) {
          if (this.currentIndex + i + 1 < this.currentList.length) {
            if (!this.currentList[this.currentIndex + i + 1]) {
              break;
            }
            lastIndex++;
            this.updateFlyLine(this.currentList[this.currentIndex + i + 1]);
          }
        }
        this.currentIndex = lastIndex;
      }
    },

    loadTrack(id) {
      return new Promise((resolve, reject) => {
        if (this.currentLoadingChunkId) {
          return;
        }
        let duration = parseInt(this.$refs.videoLive.player.duration)
        const startTime = this.getStampByDateFomat(
          this.selectEmergencyRecord.startTime
        );
        const endTime = startTime + duration * 1000;
        this.currentLoadingChunkId = id;
        // console.log(`开始下载的片段并记录：${id}`);
        const max = parseInt((endTime - startTime) / 1000);
        const taskID = this.selectEmergencyRecord.flyTaskId;
        const size = this.trackLength > max ? max : this.trackLength;
        // console.log(`this.percentage: ${this.percentage}`);
        let requestStartTime = (duration * 1000 * this.percentage) / 100 + startTime;
        const index = parseInt((requestStartTime - startTime) / 1000);
        if (this.currentIndex < index && index > 0) {
          requestStartTime = requestStartTime - 1000;
        }
        this.loadChunk(taskID, size, requestStartTime, id)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    stopLoadingTrack() {
      if (this.currentLoadingChunkId) {
        clearTimeout(this.$options.timer)
        this.stopLoadingChunkMap[this.currentLoadingChunkId] = true;
        // console.log(`已将正在执行的任务${this.currentLoadingChunkId}记录到停止运行map中`,this.stopLoadingChunkMap);
        this.currentLoadingChunkId = "";
      }
    },

    loadChunk(taskID, size, requestStartTime, id) {
      return new Promise((resolve, reject) => {
        const emergencyRecordId = this.selectEmergencyRecord.id;
        const startTime = this.getStampByDateFomat(
          this.selectEmergencyRecord.startTime
        );
        let endTime = this.getStampByDateFomat(
          this.selectEmergencyRecord.endTime
        );
        const index = parseInt((requestStartTime - startTime) / 1000);
        if (this.theChunkHasLoaded(size, requestStartTime)) {
          // console.log(`当前请求索引：${index} -- ${index + size} 已经获取继续获取后面数据`);
          return;
        }
        // console.log(`当前请求索引：${index}`);
        if (requestStartTime >= endTime) {
          return;
        }
        getUavDataModel({
          size,
          startTime: requestStartTime,
          taskID,
        }).then((res) => {
          if (res.data.code === 200) {
            if (this.selectEmergencyRecord.id == emergencyRecordId) {
              if (this.stopLoadingChunkMap[id]) {
                delete this.stopLoadingChunkMap[id];
                // console.log(`正在下载的片段：${id} 已经停止`);
                return;
              }
              let data =
                res.data.data && Array.isArray(res.data.data)
                  ? res.data.data
                  : [];
              let index = parseInt((requestStartTime - startTime) / 1000);
              for (var i = 0; i < size; i++) {
                if (index + i < this.currentList.length) {
                  this.currentList[index + i] = data[i]? data[i]: 0;
                }
              }
              // 开发环境调试工具
              // this.logTrackLoading()
              resolve();
              if (requestStartTime + size * 1000 <= endTime - 1000) {
                // let _size = size;
                // let next = requestStartTime + size * 1000;
                // if (next + _size * 1000 >= endTime) {
                //   _size = parseInt((endTime - requestStartTime) / 1000);
                // }
                this.$options.timer = setTimeout(() => {
                  this.loadChunk(taskID, size, requestStartTime + size * 1000, id);
                }, 0);
                return;
              } else {
                // console.log(`所有轨迹加载完结束:${id}`);
                this.currentLoadingChunkId = "";
                return;
              }
            }
            reject("返回数据id和当前选择巡检记录id不一致");
          } else {
            reject("轨迹加载失败");
          }
        });
      });
    },

    logTrackLoading() {
      let str = "";
      for (var i = 0; i < this.currentList.length; i++) {
        if (i == 0) {
          if (this.currentList[i]) {
            str = str + i;
          } else {
            str = str + "-";
          }
        } else {
          if (this.currentList[i] || this.currentList[i] === 0) {
            if (this.currentList[i - 1] || this.currentList[i - 1] === 0) {
              str = str + (this.currentList[i] === 0? 'N': "*");
            } else {
              str = str + i;
            }
          } else {
            if (this.currentList[i - 1] || this.currentList[i - 1] === 0) {
              str = str + i;
            } else {
              str = str + "-";
            }
          }
        }
      }
      console.log(str);
    },

    theChunkHasLoaded(size, requestStartTime) {
      let startTime = this.getStampByDateFomat(
        this.selectEmergencyRecord.startTime
      );
      let index = parseInt((requestStartTime - startTime) / 1000);
      for (var i = 0; i < size; i++) {
        if (!this.currentList[index + i] && this.currentList[index + i] !== 0) {
          return false;
        }
      }
      return true;
    },

    getStampByDateFomat(str) {
      const arr = str.split(" ");
      const date = arr[0];
      const time = arr[1];
      const dateInfo = date.split("-");
      const timeInfo = time.split(":");
      const year = Number(dateInfo[0]);
      const month = Number(dateInfo[1]);
      const day = Number(dateInfo[2]);
      const hour = Number(timeInfo[0]);
      const min = Number(timeInfo[1]);
      const second = Number(timeInfo[2]);
      return new Date(year, month - 1, day, hour, min, second).getTime();
    },

    updateFlyLine(data) {
      try {
        let lon = Number(data.lon);
        let lat = Number(data.lat);
        let yaw = Number(data.yaw);
        let height = data.fieldHeight;
        this.lonArr = [lon, lat];
        this.setWsTaskInfo(data);
        const position = Cesium.Cartesian3.fromDegrees(
          lon * 1,
          lat * 1,
          height
        );
        let node = this.flylineLayer.getNodeById("uav");
        if (!node) {
          node = new NodeMap.UAVRoute({
            id: "uav",
            positions: [position],
            uavYaw: yaw,
            style: {
              billboard: {
                image: this.plane,
                width: 56,
                height: 56,
              },
              polyline: {
                positions: [position],
                material: Cesium.Color.fromCssColorString("#AAFF00"),
                width: 2.0,
                depthFailMaterial: Cesium.Color.fromCssColorString("#AAFF00"),
              },
            }
          });
          this.flylineLayer.addNode(node);
          setTimeout(() => {
            this.$options.map3d.camera.flyTo({
              destination: new Cesium.Cartesian3.fromDegrees(
                lon,
                lat,
                1000 * 10
              ),
            });
          }, 50);
        } else {
          node.positions = node.positions.concat([position])
          node.uavYaw = yaw
          this.play = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 设置交互机制
    setEditModel() {
      this.$options.map3d._container.style.cursor = "default";
      let afterFinish = (feature) => {
        let category = feature && feature.category;
        switch (category) {
          case NodeMap.Node.WARNING:
            break;
          case NodeMap.Node.MARK:
            this.selectedMarkNode(feature);
            break;
          case NodeMap.Node.HANGAR:
            this.selectedHangarNode(feature);
            break;
          default:
            break;
        }
      };
      let updating = (feature) => {
        switch (feature.category) {
          case NodeMap.Node.MARK:
            this.updateMarkByOperation(feature)
            break;
          default:
            break;
        }
      }
      let params = {
        map: this.$options.map3d,
        vueHandler: this,
        afterFinish,
        updating,
        canEdit: false
      }
      this.$options.map3d.triggerEditModel(new EditOperation(params));
    },

    changeViewModel() {
      const newMode = this.viewModel
      let cameraInfo = this.$options.map3d.getCameraInfo();
      this.$options.map3d.scene.screenSpaceCameraController.enableTilt = false; //禁止倾斜相机
      const result = this.$options.map3d.camera.pickEllipsoid(
        new Cesium.Cartesian2(
          this.$options.map3d.scene.canvas.clientWidth / 2,
          this.$options.map3d.scene.canvas.clientHeight / 2
        )
      );

      const d = this.$options.map3d.camera.positionCartographic;
      const curPosition =
        Cesium.Ellipsoid.WGS84.cartesianToCartographic(result);

      let bound = new Cesium.BoundingSphere(
        new Cesium.Cartesian3.fromRadians(
          curPosition.longitude,
          curPosition.latitude
        ),
        d.height
      );

      if (newMode === Cesium.SceneMode.SCENE2D) {
        cameraInfo.orientation = {
          heading: 0,
          pitch: Cesium.Math.toRadians(-90.0), //look down
          roll: 0,
        };
        this.$options.map3d.camera.flyToBoundingSphere(bound, {
          offset: cameraInfo.orientation,
          duration: 0.5,
        });
      } else {
        bound.radius = d.height * 0.15;
        cameraInfo.orientation = {
          heading: 0,
          pitch: Cesium.Math.toRadians(-45.0), //look in 3d
          roll: 0,
        };
        this.$options.map3d.camera.flyToBoundingSphere(bound, {
          offset: cameraInfo.orientation,
          duration: 0.5,
        });
      }
    },

    zoom(amount) {
      let cartographic;
      if (this.viewModel === 3) {
        const cameraPos = this.$options.map3d.camera.position;
        const ellipsoid = this.$options.map3d.scene.globe.ellipsoid;
        cartographic = ellipsoid.cartesianToCartographic(cameraPos);
      } else {
        cartographic = this.$options.map3d.camera.positionCartographic;
      }
      const height = cartographic.height;
      const centerLon = Cesium.Math.toDegrees(cartographic.longitude);
      const centerLat = Cesium.Math.toDegrees(cartographic.latitude);

      // 镜头动画
      this.$options.map3d.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          centerLon,
          centerLat,
          amount > 0 ? height / 1.8 : height * 1.8
        ),
        duration: 1.0,
      });
    },

    switchLayerType() {
      this.isVec = !this.isVec;
      const type = this.isVec ? "vector" : "satellite";
      this.$options.map3d.switchLayer(type);
    },

    closePanel() {
      this.showMarkPanel = false;
    },

    closeSiderContent() {
      this.endPlay();
      this.currentIndex = 0;
      // 清空缓存数据
      this.currentList = [];
      if (this.$refs.videoLive) {
        this.$refs.videoLive.closeLive();
      }
      this.flylineLayer.clear();
      this.siderContentShow = false;
      this.percentage = 0;
      setTimeout(() => {
        if (this.$refs.videoLive) {
          this.$refs.videoLive.destroy();
        }
      }, 0);
    },

    showLive() {
      if (!this.selectEmergencyRecord || !this.selectEmergencyRecord.id) {
        this.$refs.videoLive.closeLive();
        return this.$message.warning("请在左侧列表，选择一个巡检对象");
      }
      // 进度条loading
      this.videoLoading = true;
      this.siderContentShow = true;
      // 加载视频数据源
      this.switchVideoURL().then(res=>{
        let duration = parseInt(this.$refs.videoLive.player.duration)
        const startTime = this.getStampByDateFomat(
          this.selectEmergencyRecord.startTime
        );
        const endTime = startTime + duration * 1000;
        const size = parseInt((endTime - startTime) / 1000);
        this.currentList.length = size;
        this.loadTrack(Cesium.createGuid())
      }).catch(()=>{
        throw '视频加载失败'
      })
      
    },

    // 设置右侧面板属性
    setCurrentEntity(value) {
      this.markEntity = JSON.parse(
        JSON.stringify(Object.assign(this.markEntity, value))
      );
    },

    moveToNavigationById(id) {
      const node = this.navigationLayer.getNodeById(id);
      if (!node) {
        return
      }
      const cartesian = node.positions[0];
      const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
      // 弧度转为角度（经纬度）
      const lon = Cesium.Math.toDegrees(cartographic.longitude);
      const lat = Cesium.Math.toDegrees(cartographic.latitude);
      this.$options.map3d.scene.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(lon, lat, 1000 * 10),
      });
    },

    // 定位
    flyTo(lng, lat, height) {
      if (typeof lng !== "number" && typeof lat !== "number") {
        return;
      }
      this.$options.map3d.camera.flyTo({
        destination: new Cesium.Cartesian3.fromDegrees(
          lng,
          lat,
          height ? height : 2000
        ),
        orientation: {
          heading: 0,
          pitch: Cesium.Math.toRadians(-90.0), //look down
          roll: 0,
        },
      });
    },

    getEmergencyRecordDetail() {
      this.loadingEmergencyRecord = true;
      getEmergencyRecordDetail({
        id: this.selectEmergencyRecord.id,
      })
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data.id === this.selectEmergencyRecord.id) {
              this.showETRecord = data;
              this.addNavigationInMap(data);
            }
          }
        })
        .finally(() => {
          this.loadingEmergencyRecord = false;
        });
    },

    //
    warningSuccess() {}
  },
  beforeDestroy() {
    this.endChecked();
    removeEvent(window, "resize", this.resize);
    this.$options.noFlyZoneHook && this.$options.noFlyZoneHook.clearNoFlyZone();
    this.$options.noFlyZoneHook = null;
    this.$options.map3d && this.$options.map3d.destroy();
  },
};
</script>

<style scoped lang="scss">
.ET-records-map-box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  #cesiumContainer_ET_records {
    width: 100%;
    height: 100%;
  }

  .footer {
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 10;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    .play-controller {
      width: calc(100% - 100px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      .video-play {
        width: 20px;
        font-size: 20px;
        cursor: pointer;
      }
      .processing {
        cursor: pointer;
        margin-left: 10px;
        position: relative;
        width: calc(100% - 120px);
        position: relative;
        top: 1px;
        .processing-controller {
          cursor: pointer;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
          background-color: #fff;
          width: 16px;
          height: 16px;
          border-radius: 8px;
        }
      }
    }
    .tool-controller {
      width: 100px;
      display: flex;
      justify-content: end;
      .full {
        width: 16px;
        height: 16px;
      }
    }
  }

  .min-margin {
    top: 16px !important;
    padding: 10px 33px 10px 33px !important;
    .inspection-pannel-item:not(:last-child) {
      margin-bottom: 4px !important;
    }
  }

  .inspection-pannel {
    box-sizing: border-box;
    width: 346px;
    background-color: #0c0c0ccc;
    min-height: 100px;
    border-radius: 8px;
    position: absolute;
    right: 26px;
    top: 28px;
    padding: 25px 33px 33px 33px;
    color: #ffffff;
    .inspection-pannel-item:not(:last-child) {
      margin-bottom: 15px;
    }
    .inspection-pannel-item {
      font-size: 14px;
      line-height: 18px;
      .pannel-item-title {
        display: inline-block;
        vertical-align: top;
        width: 73px;
      }
      .pannel-item-content {
        display: inline-block;
        vertical-align: top;
        width: calc(100% - 74px);
        word-wrap: break-word;
        word-break: break-all;
      }
      .edit-icon {
        cursor: pointer;
      }
    }
    .inspection-pannel-item:first-child {
      .pannel-item-content {
        color: #387bed;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  #baseController {
    position: absolute;
    bottom: 50px;
    right: 1%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .no-fly {
      padding: 4px 5px 5px 5px;
      background-color: #2d333e;
      margin-bottom: 10px;
      border-radius: 4px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
    }
    #navigationHelpButtonContainer {
      width: 21px;
      height: 42px;
      margin-bottom: 10px;
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #2d333e;
      color: #fff;
      cursor: pointer;
      border: 1px solid #2d333e;
    }

    .forbidden {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      margin-bottom: 10px;
    }

    .drawBtn {
      width: 40px;
      height: 40px;
    }

    .zoomBtn {
      width: 30px;
      height: 30px;
    }

    .btn > img {
      width: 16px;
    }

    .bottomBorder {
      border-bottom: 1px solid #171c28;
    }
    .btn:hover {
      filter: brightness(150%);
      border: 1px solid #171c28;
    }
    .flexCenter {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .layerSwitch {
      margin-top: 20px;
      width: 32px;
      height: 32px;
      border-radius: 18px;
      border: 2px solid #fff;
      cursor: pointer;
    }
  }

  .open-ET-records-panel {
    right: 286px !important;
  }

  .sider-content {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    position: absolute;
    z-index: 98;
    min-width: 150px;
    box-sizing: border-box;
    .content-header {
      font-size: 14px;
      font-weight: 600;
      border-bottom: 1px solid #fff;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      box-sizing: border-box;
      .close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        cursor: pointer;
      }
    }
    .height-258 {
      height: 258px;
    }
    .video-container {
      height: 100%;
    }
    .videoBtn {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      width: 74px;
      height: 58px;
      background-color: #000000AA;
      border-radius: 3px;
      background-image: url('/img/taskCenter/taskPage/taskback.png');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .ET-records-panel {
    z-index: 99;
  }

  .scaler {
    position: absolute;
    bottom: 3%;
    padding: 2px;
    right: 1%;
    color: #fff;
    z-index: 10;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    .scaler-text {
      text-align: center;
    }
    .scaler-ui {
      width: 100%;
      height: 10px;
      border: 1px solid #fff;
      border-top: none;
    }
  }
}
</style>

<style lang="scss">
.nofly-tooltip {
  text-align: left;
  font-size: 13px;
  .nofly-tooltip-main {
    .tooltip-main_top {
      margin-bottom: 8px;
      .el-switch.is-checked .el-switch__core::after {
        margin-left: -14px;
      }
      .el-switch__core {
        height: 16px;
        &::after {
          width: 13px;
          height: 13px;
        }
      }
      .el-switch__label * {
        color: #fff;
        font-size: 13px;
      }
    }
    .tooltip-main_bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .el-checkbox.is-bordered {
        margin-left: 0;
        margin-top: 3px;
        display: flex;
        align-items: center;
        &:first-child {
          margin-top: 0;
        }
        &.el-checkbox--small {
          padding: 0;
          height: auto;
          border: none;
          .el-checkbox__label {
            display: flex;
            align-items: center;
            font-size: 13px;
            color: #fff;
            img {
              width: 18px;
              height: 18px;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
}
</style>
